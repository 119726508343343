import {
    AccordionContainerDPC,
    AccordionDPC,
    TabsetDPC,
    AllegatoContainerDPC,
    AllegatoDPC,
    PageContainerDPC,
    LinksContainerDPC,
    LinksDPC,
    PrintButtonDPC
} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import {getSlug, getAccordionAndTabLinks, prop} from "../functions/functions";
import moment from "moment";
import "moment/locale/it";

export default ({data, pageContext}) => {

  const language = '/' + pageContext.lang;

  const correlazioniTypes = ['comunicato_stampa','notizia','approfondimento','pubblicazione', 'corso', 'pagina', 'sezione'];

  const fotoVideoTypes = ['galleria_foto','galleria_flickr','video'];

  const correlazioni = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria == null || correlazioniTypes.indexOf(item.field_categoria_primaria) > -1).map(item => {
    return {
        title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '<b>Sezione</b>') + ' - ' + item.field_titolo_esteso,
        slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
    }   
  });

  const fotoVideo = data.node.relationships.field_correlazioni.filter(item => fotoVideoTypes.indexOf(item.field_categoria_primaria) > -1).map(item => {
    return {
        title: (item.field_categoria_primaria === 'video' ? '<b>Video</b>' : '<b>Foto</b>') + ' - ' + item.field_titolo_esteso,
        slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
    }   
  });

  const bandoDiGara = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'bando_di_gara').map(item => {
    return {
        title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
        slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
    }   
  });

  const mappe = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'mappa').map(item => {
    return {
        title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
        slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
    }   
  });

  const timeline = data.node.relationships.field_correlazioni.filter(item => item.field_categoria_primaria === 'timeline').map(item => {
    return {
        title: (item.field_categoria_primaria ? '<b>' +item.field_categoria_primaria.charAt(0).toUpperCase() + item.field_categoria_primaria.replace(/_/g, ' ').slice(1)+'</b>' : '') + ' - ' + item.field_titolo_esteso,
        slug: getSlug(language + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
    }   
  });

    const normative = data.node.relationships.field_normative.map(item => {
        return {
            title: item.field_titolo_esteso,
            slug: getSlug('/' + pageContext.lang + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name)
        }
    })

    const linkEsterni = data.node.field_link_esterni ? data.node.field_link_esterni.map(item => {
      return {
          title: item.title,
          titleLinkEsterno: "Val al sito " + item.uri,
          slug: item.uri
      }
    }) : [];

    const tabItems = (data.node.relationships.field_tab && data.node.relationships.field_tab.length > 0) && data.node.relationships.field_tab.map(item => {
        return {
            tabella: item.field_tabella ? item.field_tabella.processed : null,
            body: item.field_testo ? item.field_testo.processed : null,
            title: item.field_titolo ? item.field_titolo : null,
            img: item.relationships ? item.relationships.field_immagine : null,
            video: item.relationships ? item.relationships.field_video : null
        }
    })

    const breadcrumbItem = {
      name: data.node.field_titolo_esteso,
      slug: data.node.fields && data.node.fields.slug ? data.node.fields.slug : null,
      active: true
    }
    moment.locale('it');
    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"} checkTorna={true}
                          //linkTorna={`/${pageContext.lang}${data.elenco?.fields.slug}`}
                          //textTorna={data.elenco?.title}
                          elencoId={data.elenco?.drupal_internal__nid}
                          menuItems={pageContext.menu}
                          pageId={data.node.drupal_internal__nid}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          breadcrumbItem={breadcrumbItem}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >
            <div className="container">
                <div className="px-custom">
                    <div className="row">
                        <div className="col-12">
                            <article>
                                <div className="category-top">
                                    <span className="data">{moment(data.node.field_data).format("DD MMMM YYYY")}</span>
                                </div>
                                <h1>{data.node.field_titolo_esteso}</h1>
                                <div className="news-abstract"
                                     dangerouslySetInnerHTML={{__html: data.node.field_abstract ? data.node.field_abstract.processed : ''}}/>
                                <div>
                                    {data.node.relationships.field_immagine_dettaglio &&
                                    <img
                                        alt={data.node.relationships.field_immagine_dettaglio.field_alt}
                                        src={data.node.relationships.field_immagine_dettaglio.relationships?.image?.localFile?.publicURL}
                                        className="img-fluid float-left"
                                    />}
                                    <div dangerouslySetInnerHTML={{__html: data.node.body ? data.node.body.processed : ''}}/>
                                </div>


                                {data.node.relationships.field_accordion && data.node.relationships.field_accordion.length > 0 ?
                                    <AccordionContainerDPC id="1">
                                        {
                                            data.node.relationships.field_accordion.map((node) => (
                                                <AccordionDPC
                                                    title={node.field_titolo ? node.field_titolo : ''}
                                                    body={node.field_testo ? node.field_testo.processed : ''}
                                                    id={node.drupal_internal__id}
                                                    accordionId={"1"}
                                                    img={node.relationships.field_immagine}
                                                    tabella={node.field_tabella ? node.field_tabella.processed : null}
                                                    video={node.relationships.field_video}
                                                    links= {node.relationships ? getAccordionAndTabLinks(node.relationships.field_link_interni, pageContext.lang, process.env.PORTALE): null}
                                                />
                                            ))
                                        }
                                    </AccordionContainerDPC>
                                    : null}


                                {(tabItems && tabItems.length > 0) &&
                                <TabsetDPC id="Tabset1" items={tabItems}/>}


                                {data.node.field_tabella ?
                                    <div className="table-responsive mb-4">
                                        <div className="table table-custom"
                                               dangerouslySetInnerHTML={{__html: data.node.field_tabella.processed}}/>
                                    </div>
                                    : null}

                                <section className="attachments-and-links">
                                {Array.isArray(data.node.relationships.field_allegati) && data.node.relationships.field_allegati.length>0&&
                                    <AllegatoContainerDPC titoloSezione={'Allegati'}>                  
                                            {data.node.relationships.field_allegati.map(allegato => (
                                                <AllegatoDPC
                                                    titolo={allegato.field_titolo_esteso}
                                                    descrizione={allegato.relationships?.field_allegato?.description}
                                                    dimension={allegato.relationships?.field_allegato?.filesize}
                                                    slug={allegato.relationships?.field_allegato?.localFile?.publicURL}
                                                    extension={allegato.relationships?.field_allegato?.localFile?.extension}
                                                />
                                            ))
                                        }
                                    </AllegatoContainerDPC>}

                                    {normative && normative.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Normativa'}>
                                        <LinksDPC links={normative}/>
                                    </LinksContainerDPC>}

                                    {bandoDiGara && bandoDiGara.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Avvisi e bandi di gara'}>
                                        <LinksDPC links={bandoDiGara}/>
                                    </LinksContainerDPC>}

                                    {correlazioni && correlazioni.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Per saperne di più'}>
                                        <LinksDPC links={correlazioni}/>
                                    </LinksContainerDPC>}

                                    {fotoVideo && fotoVideo.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Foto e video'}>
                                        <LinksDPC links={fotoVideo}/>
                                    </LinksContainerDPC>}                                  

                                    {mappe && mappe.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Mappe'}>
                                        <LinksDPC links={mappe}/>
                                    </LinksContainerDPC>}

                                    {timeline && timeline.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Timeline'}>
                                        <LinksDPC links={timeline}/>
                                    </LinksContainerDPC>}

                                    {linkEsterni && linkEsterni.length > 0 &&
                                    <LinksContainerDPC titoloSezione={'Link esterni'}>
                                        <LinksDPC links={linkEsterni}/>
                                    </LinksContainerDPC>}
                                </section>
                                <PrintButtonDPC/>
                            </article>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainerDPC>
    );
};


export const query = graphql`
query($slug: String!) {
  node:nodeBandoDiGara (fields: { slug: { eq: $slug } }) {
    drupal_internal__nid
    title
    field_titolo_esteso
    field_categoria_primaria
    field_id_contenuto_originale
    field_data
    field_link_esterni {
      title
      uri
    }
    field_abstract {
      processed
    }
    body {
      processed
    }
    fields {
      slug
    }
    field_tabella {
      processed
    }
    relationships {
      field_riferimento_traduzione {
        fields {
          slug
        }
      }
      field_sottodominio {
        name
      }
      field_accordion {
        field_titolo
          field_tabella {
            processed
          }
          field_testo {
            processed
            value
          }
          relationships {
            field_immagine {
              field_alt
              field_didascalia
              relationships {
                image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                      fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            field_video {
              field_video {
                title
                uri
              }
              field_didascalia
              title
            }
            field_link_interni{
              ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
            }
          }
          drupal_internal__id
      }
      field_tab {
        field_titolo
        field_tabella {
            processed
          }
          field_testo {
            processed
            value
          }
          relationships {
            field_immagine {
              field_alt
              field_didascalia
              relationships {
                image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                      fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                    }
                  }
                }
              }
            }
            field_video {
              field_video {
                title
                uri
              }
              field_didascalia
              title
            }
            field_link_interni{
             ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
            }
          }
          drupal_internal__id
      }
      field_immagine_dettaglio {
        field_alt
        field_didascalia
        field_titolo_esteso
        relationships {
          image:field_immagine {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
      field_immagine_anteprima {
        field_alt
        field_didascalia
        field_titolo_esteso
        relationships {
          image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                fluid(maxWidth: 1100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
        }
        }
      }
      field_allegati {
        title
        field_titolo_esteso
        relationships {
          field_allegato {
            filesize
            localFile {
              publicURL
              extension
            }
            filename
          }
        }
      }
      field_correlazioni {
        ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
      }
      field_normative {
        ...normativaFragment
      }
    }
  },
  elenco:nodeElencoBandiDiGaraEContratti (field_codice_lingua: {eq: false}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}) {
      title
      field_titolo_esteso
      fields {
        slug
      }
      drupal_internal__nid
  },
}
`;
